import axios from "axios";
import _find from "lodash/find";
import _replace from "lodash/replace";
import api from "../config/api";
import TableHead from "../../templates/partials/TableHead.vue";
import data from "../lib/sharedComponentData";
import methods from "../lib/sharedComponentMethods";

const team = {
  name: "Team",
  props: ["periodIndex", "id"],
  data() {
    return Object.assign(
      {
        teamActionAndEvents: [],
        teamPeriod: {},
        teamReporting: {},
        reporting: [],
        name: "",
        intervalId: null,
        seminarType: document
          .querySelector("[seminar-type]")
          .getAttribute("seminar-type")
      },
      data
    );
  },
  mounted() {
    this.getTeamReporting();
    this.intervalId = this.startPolling(["getTeamReporting"]);
    this.getTeam();
  },
  destroyed() {
    this.stopPolling(this.intervalId);
  },
  methods: Object.assign(
    {
      getTeam() {
        axios
          .get(
            `${api.getUrl()}/api/frontend/team/${this.id}`
          )
          .then(response => {
            this.name = response.data.name;
          })
          .catch(err => this.errors.push(err));
      },
      getTeamReporting() {
        axios
          .get(`${api.getUrl()}/api/frontend/reporting/?pIndex=${this.periodIndex}`)
          .then(response => {
            this.reporting = response.data.report;
            const teamReporting = this.reporting.find(t => t.id === parseInt(this.id))
            this.teamReporting = teamReporting.period;
            this.teamFlatInfo();
          })
          .catch(err => this.errors.push(err));
      },
      teamFlatInfo() {
        // only adds the things to the list if they are not yet in there
        this.teamReporting.action.forEach(action => {
          if (
            _find(this.teamActionAndEvents, a => a.id === action.id) ===
            undefined
          ) {
            this.teamActionAndEvents.push(action);
          }
        });
        this.teamReporting.event.forEach(event => {
          if (
            _find(this.teamActionAndEvents, a => a.id === event.id) ===
            undefined
          ) {
            this.teamActionAndEvents.push(event);
          }
        });
        this.teamActionAndEvents.sort((a1, a2) => a1.id - a2.id); // TODO: Maybe sort by .created instead of pk
        this.teamActionAndEvents.forEach(item => {
          const tempArray = this.teamActionAndEvents;
          const highestAccount = tempArray[tempArray.length - 1].account / 100;
          this.setPercentageValues(item, highestAccount);
        });
      },
      addPlus(value) {
        if (value <= 0) {
          return "";
        }
        return "+";
      },
      computeDifference(
        valueArray,
        currentIndex,
        valueType,
        toFixed = false,
        fixed = 2,
        division = false,
        divisor = 1
      ) {
        const oldValue = valueArray[currentIndex - 1];
        const newValue = valueArray[currentIndex];
        let r = newValue[valueType];
        if (currentIndex > 0) {
          r = newValue[valueType] - oldValue[valueType];
        }
        if (division) {
          r /= divisor;
        }
        if (toFixed) {
          r = r.toFixed(fixed);
        }
        if (currentIndex > 0) {
          return _replace(`${this.addPlus(r)}${r}`, ".", ",");
        }
        return _replace(r, ".", ",");
      }
    },
    methods
  ),
  components: {
    "table-head": TableHead
  }
};

export default team;
