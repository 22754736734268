import axios from "axios";
import LocalStorageService from "./LocalStorageService";
import api from "@/js/config/api";
import router from './../router'

// LocalstorageService
const localStorageService = LocalStorageService.getService();

// Add a request interceptor, always inject Bearertoken into request
axios.interceptors.request.use(
   config => {
       const token = localStorageService.getAccessToken();
       if (token) {
           config.headers['Authorization'] = 'Bearer ' + token;
       }
       // config.headers['Content-Type'] = 'application/json';
       return config;
   },
   error => {
       Promise.reject(error)
   });



//Add a response interceptor

axios.interceptors.response.use((response) => {
   return response
}, function (error) {
   const originalRequest = error.config;

   // When refresh token request fails (refresh token expired)

   if (error.response.status === 401 && originalRequest.url === `${api.getUrl()}/api/token/refresh/`) {
      router.push('/login/');
      return Promise.reject(error);
   }

   // When request fails with authentication failed, try to get new token via refresh token
   if (error.response.status === 401 && !originalRequest._retry) {
       originalRequest._retry = true;
       const refreshToken = localStorageService.getRefreshToken();
       if(refreshToken) {
        return axios.post(`${api.getUrl()}/api/token/refresh/`,
           {
               "refresh": refreshToken
           })
           .then(res => {
               if (res.status === 200) {
                   localStorageService.setToken(res.data);
                   axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorageService.getAccessToken();
                   return axios(originalRequest);
               }
           })
       }
   }

   return Promise.reject(error);
});

export default axios
