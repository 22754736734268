<template>
  <div class="reporting">
    <ul class="collapsible popout expandable">
      <li
        v-for="period in periods"
        :key="period.id"
      >
        <div class="collapsible-header">
          <h1>{{ period.title }}</h1>
          <i class="fa angle" />
        </div>
        <div class="collapsible-body">
          <table class="highlight">
            <thead>
              <tr>
                <table-head
                  title="Team"
                  icon="team"
                  :blend="true"
                  :data="{periodId: period.id, column: 'team'}"
                  :blend-in-first="true"
                  :sort="false"
                  @blend="showHideColumn"
                />
                <table-head
                  :ref="`sortable${period.id}`"
                  title="Kontostand"
                  :blend="true"
                  icon="account"
                  :data="{periodId: period.id, column: 'account'}"
                  :sort="true"
                  @sort="sortColumn"
                  @blend="showHideColumn"
                />
                <table-head
                  :ref="`sortable${period.id}`"
                  title="Zufriedenheit"
                  :blend="true"
                  :data="{periodId: period.id, column: 'satisfaction'}"
                  :sort="true"
                  icon="satisfaction"
                  @sort="sortColumn"
                  @blend="showHideColumn"
                />
                <table-head
                  :ref="`sortable${period.id}`"
                  title="Freizeit"
                  :blend="true"
                  :data="{periodId: period.id, column: 'leisure'}"
                  :sort="true"
                  icon="leisure"
                  @sort="sortColumn"
                  @blend="showHideColumn"
                />
                <table-head
                  :ref="`sortable${period.id}`"
                  title="Schule"
                  :blend="true"
                  :data="{periodId: period.id, column: 'education'}"
                  :sort="true"
                  icon="school"
                  @sort="sortColumn"
                  @blend="showHideColumn"
                />
                <table-head
                  :ref="`sortable${period.id}`"
                  title="Punktzahl"
                  :blend="true"
                  :data="{periodId: period.id, column: 'points'}"
                  :sort="true"
                  icon="points"
                  @sort="sortColumn"
                  @blend="showHideColumn"
                />
                <table-head
                  :ref="`sortable${period.id}`"
                  title="Platz"
                  :blend="true"
                  :data="{periodId: period.id, column: 'rank'}"
                  :sort="true"
                  icon="placement"
                  @sort="sortColumn"
                  @blend="showHideColumn"
                />
                <table-head
                  title="Bonuspunkte"
                  icon="bonus"
                />
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(team) in reporting[period.id]"
                :key="team.id"
              >
                <td>
                  <team-name
                    :id="team.id"
                    :ref="`teamName${period.id}`"
                    :name="team.teamName"
                    :period-index="period.id+1"
                    :isTH="false"
                  />
                </td>
                <td :class="isSorted(period.id, 'account')">
                  <reporting-value
                    :ref="`teamValue${period.id}`"
                    name="account"
                    :value="team.latestEntry.account"
                    :value-percentage="team.accountPercentage"
                  />
                </td>
                <td :class="isSorted(period.id, 'satisfaction')">
                  <reporting-value
                    :ref="`teamValue${period.id}`"
                    name="satisfaction"
                    :value="team.latestEntry.satisfaction"
                    :value-percentage="team.satisfactionPercentage"
                  />
                </td>
                <td :class="isSorted(period.id, 'leisure')">
                  <reporting-value
                    :ref="`teamValue${period.id}`"
                    name="leisure"
                    :value="team.latestEntry.leisure"
                    :value-percentage="team.leisurePercentage"
                  />
                </td>
                <td :class="isSorted(period.id, 'education')">
                  <reporting-value
                    :ref="`teamValue${period.id}`"
                    name="education"
                    :value="team.latestEntry.education"
                    :value-percentage="team.educationPercentage"
                  />
                </td>
                <td :class="isSorted(period.id, 'points')">
                  <reporting-value
                    :ref="`teamValue${period.id}`"
                    name="points"
                    :value="team.points"
                    :real-points="team.realPoints"
                    :value-percentage="team.pointsPercentage"
                    :seminar="team.seminar_type"
                  />
                </td>
                <td :class="isSorted(period.id, 'rank')">
                  <reporting-value
                    :ref="`teamValue${period.id}`"
                    name="rank"
                    :value="team.latestEntry.rank"
                  />
                </td>
                <td>
                  <div
                    v-for="(bonus) in team.period.bonuses"
                    :key="bonus._id"
                    class="bonus text"
                    :class="bonus.name"
                  >
                    <i
                      class="gh gh-small"
                      :class="`gh-${bonus.name}`"
                    />
                    {{ bonusTexts[bonus.name] }} ({{ bonus.value }})
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="8">
                  <bonus
                    :current-report="reporting[period.id]"
                    :period-id="period.id"
                    :active-buttons="isBonusActive(period.id)"
                    @refresh="refreshReporting"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
  import Reporting from "../../../js/components/Reporting.js";
  export default {
    name: "Reporting_GH_FH",
    mixins: [Reporting],
  }
</script>
