const tableHeader = {
  name: 'TableHead',
  props: {
    title: {
      type: String
    },
    icon: {
      type: String
    },
    align: {
      default: '',
      type: String
    },
    sort: {
      default: false,
      type: Boolean
    },
    blend: {
      default: false,
      type: Boolean
    },
    blendInFirst: {
      default: false,
      type: Boolean
    },
    data: null
  },
  data () {
    return {
      asc: true,
      desc: true,
      blendin: false,
      blendout: true,
      valignWrapper: 'valign-wrapper'
    }
  },
  mounted () {
    if (this.blendInFirst) {
      this.blendin = true;
      this.blendout = false
    }

    if (this.align === 'center') {
      this.valignWrapper = ''
    }
  },
  methods: {
    setSort () {
      if (!this.sort) {
        return false
      }

      const emitParameters = this.data;
      if (this.asc && this.desc) {
        this.desc = false;
        this.asc = true;
        emitParameters.sort = 'asc'
      } else if (!this.desc) {
        this.asc = false;
        this.desc = true;
        emitParameters.sort = 'desc'
      } else {
        this.asc = true;
        this.desc = false;
        emitParameters.sort = 'asc'
      }

      this.$emit('sort', emitParameters);
      return true
    },
    setDefault () {
      this.asc = true;
      this.desc = true
    },
    setBlend () {
      if (!this.blend) {
        return false
      }

      const emitBlend = this.data;
      if (this.blendin) {
        this.blendin = false;
        this.blendout = true;
        emitBlend.blend = 'in';
        this.$emit('blend', emitBlend);
        return true
      }
      if (this.blendout) {
        this.blendin = true;
        this.blendout = false;
        emitBlend.blend = 'out';
        this.$emit('blend', emitBlend);
        return true
      }
      return true
    }
  }
};

export default tableHeader
