import { template } from "lodash";
import _forEach from "lodash/forEach";
import _sortBy from "lodash/sortBy";

const sharedComponentMethods = {
  percentageIterator() {
    _forEach(this.reporting, (period) => {
      _forEach(period, (team) => {
        this.setBalance(team);
      });
      this.setPlacement(period);
    });
  },
  setPlacement(period) {
    var lastTeam;
    const sortedByPoints = _sortBy(period, (team) => team.points);
    sortedByPoints.reverse().forEach((team, index) => {
      const tmpTeam = team;
      if (index === 0) {
        if (tmpTeam.points !== null) {
          tmpTeam.latestEntry.rank = index + 1;
        }
      } else {
        if (tmpTeam.points !== null) {
          if (tmpTeam.points === lastTeam.points) {
            tmpTeam.latestEntry.rank = lastTeam.latestEntry.rank;
          } else {
            tmpTeam.latestEntry.rank = index + 1;
          }
        }
      }
      lastTeam = tmpTeam;
    });
  },
  setBalance(item) {
    const resItem = item;

    resItem.balance = this.getBalance(
      resItem.accountPercentage,
      resItem.satisfactionPercentage,
      resItem.leisurePercentage,
      resItem.educationPercentage
    );
  },
  getBalance(account, satisfaction, leisure, education) {
    const v1 = Math.abs(account - satisfaction);
    const v2 = Math.abs(account - leisure);
    const v3 = Math.abs(account - education);
    const v4 = Math.abs(satisfaction - leisure);
    const v5 = Math.abs(leisure - satisfaction);
    const v6 = Math.abs(education - satisfaction);
    const balance = (v1 + v2 + v3 + v4 + v5 + v6) / 6;
    return balance;
  },
  startPolling(functionArray) {
    const intervalId = window.setInterval(() => {
      functionArray.forEach((func) => {
        this[func]();
      });
    }, this.pollFrequence * 1000);
    return intervalId;
  },
  stopPolling(intervalId) {
    window.clearInterval(intervalId);
  },
  dateFormat(date) {
    const msInMinutes = 60000;
    const returnDate = new Date(date);
    const now = new Date();
    if (returnDate.toString() === "Invalid Date") {
      return undefined;
    }
    const minutesAgo = Math.abs((now - returnDate) / msInMinutes).toFixed(0);
    return {
      minutesAgo,
      date: returnDate.toLocaleDateString(),
      time: returnDate.toLocaleTimeString(),
    };
  },
};

export default sharedComponentMethods;
