const isKeyInObject = (obj, key) => {
  const res = Object.keys(obj).some(v => v === key);
  return res
};

const removeDiacriticalMarks = (string) => {
  let r = string.replace(/[ÇçĊċŁłĞğ]/, '');
  r = r.replace(/[ĀĂĄ]/, 'A');
  r = r.replace(/[ĒĔĘ]/, 'E');
  r = r.replace(/[ĪĬĮ]/, 'I');
  r = r.replace(/[ŌŎǪ]/, 'O');
  r = r.replace(/[ŪŬŲ]/, 'U');
  r = r.replace(/[āăą]/, 'a');
  r = r.replace(/[ēĕę]/, 'e');
  r = r.replace(/[īĭį]/, 'i');
  r = r.replace(/[ōŏǫ]/, 'o');
  r = r.replace(/[ūŭų]/, 'u');
  return r
};

const Helpers = {
  isKeyInObject,
  removeDiacriticalMarks
};

export default Helpers
