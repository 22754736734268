const sharedComponentData = {
  periods: [],
  actualSeminar: {},
  actualTeams: [],
  actualTeam: {},
  actualTeamPhase: [],
  actualPeriodIndex: 0,
  pollFrequence: 3,
  intervalId: undefined,
  bonusTexts: {
    leisure: "Meiste Freizeit",
    realPoints: "Erfolgreichstes Team",
    account: "Höchster Kontostand",
    satisfaction: "Größte Zufriedenheit",
    education: "Beste Schulnoten",
    job: "Erfolgreich im Beruf",
    depot_change_absolute: "TradingHero",
    manually: "Manuelle Bonuspunkte",
    balance: "Ausgeglichenstes Team",
    chooseTeam: "Team auswählen",
    bonusPoints: "Punkte eintragen",
    cancel: "Abbrechen",
    add: "Hinzufügen",
    lifeOverview: "Spar-Ziel",
    transactions_made: "Buy'n'Holder",
    experimentBonus: "Experimentierkasten gekauft",
  },
  reportingTexts: {
    hidden: "versteckt",
  },
  // TODO: used in spots to determine if the last round was reached, was initially 4 because 5 rounds are played
  lastPeriod: 4,
  errors: [],
};

export default sharedComponentData;
