const api = {
  getUrl () {
    const { location } = window;
    const { protocol } = location;
    const { hostname } = location;
    const { port } = location;
    let url = null;
    url = `${protocol}//${hostname}:${port}`;
    // dev env
    if (hostname.includes('localhost')) {
      url = 'http://localhost:3000'
    }
    return url
  },
  getAuthToken () {
    const secret = 'secret4myGiroHeroWebService';
    const token = window.btoa(secret);
    const config = { headers: { Authorization: token } };
    return config
  }
};

export default api
