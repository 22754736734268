import Vue from 'vue'
import './js/lib/Materialize'
import './scss/main.scss'
import Root from './templates/Root.vue'
import router from './js/router'
import 'chartjs-plugin-annotation'


Vue.config.productionTip = true;

/* eslint-disable no-new */
new Vue({
  router,
  render: h => h(Root)
}).$mount('#app');
