import LocalStorageService from "../lib/LocalStorageService";;
import axios from '../lib/AxiosClient'
import api from "@/js/config/api";

export default {
  props: {
    setLoginState: Function
  },
  async mounted() {
    this.logout()
  },
  methods: {
    logout() {
      if(window._env_.VUE_APP_IS_LOCAL) {
        axios.post(`${api.getUrl()}/api/frontend/handle-logout`, {}).then((response) => {
          console.log("This code is not playing anymore!")
          LocalStorageService.clearToken()
          this.setLoginState(false)
        }, () => {
          this.error = true
        })
      } else {
        LocalStorageService.clearToken()
        this.setLoginState(false)
      }
    },
  }
}
