import axios from '../lib/AxiosClient'
import LocalStorageService from "../lib/LocalStorageService";
import api from "@/js/config/api";
import router from "@/js/router";
import HeroesLogo from '../../img/HeroesFinanzbildung-Logo.svg'

export default {
  props: {
    setLoginState: Function
  },
  data() {
    return {
      password: "",
      code: "",
      error: false,
      image: HeroesLogo,
    }
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.error = false
      axios.post(`${api.getUrl()}/api/token/`, {code: this.code, password: this.password}).then((response) => {
        if(response.status === 200) {
          LocalStorageService.setToken(response.data)
          this.setLoginState(true)
          if(window._env_.VUE_APP_IS_LOCAL) {
            axios.post(`${api.getUrl()}/api/frontend/handle-login`, {code: this.code, password: this.password}).then((response) => {
              console.log("This code is playing now!")
            }, () => {
              this.error = true
            })
          }
        }
        router.push('/state/');
      }, () => {
        this.error = true
      })
    }
  }
}
