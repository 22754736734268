import { render, staticRenderFns } from "./Certificate.vue?vue&type=template&id=295c3504&scoped=true&"
import script from "../js/components/Certificate.js?vue&type=script&lang=js&"
export * from "../js/components/Certificate.js?vue&type=script&lang=js&"
import style0 from "./Certificate.vue?vue&type=style&index=0&id=295c3504&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "295c3504",
  null
  
)

export default component.exports