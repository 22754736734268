<script>
import { Line } from "vue-chartjs";

export default {
  extends: Line,
  props: {
    chartdata: {
      type: Object,
      default: null
    },
    options: {
      type: Object,
      default: null
    },
    handleClick: Function,
    legendData: {
      type: Array,
      default: Function
    },
  },
  mounted () {
    const that = this;
    this.legendData.forEach((hidden,i)=>{
         that.chartdata.datasets[i].hidden = hidden;
     });
    this.renderChart(this.chartdata, {...this.options, legend: { onClick(event, legendItem) {
      that.handleClick(legendItem.datasetIndex);
      Chart.defaults.global.legend.onClick.call(this, event, legendItem)
    }}});
  },
  updated () {
    console.log("Updated!");
    this.renderChart(this.chartdata, this.options)
  }
};
</script>
