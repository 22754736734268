import axios from "../lib/AxiosClient";
import Materialize from "materialize-css";
import _orderBy from "lodash/orderBy";
import _remove from "lodash/remove";
import _countBy from "lodash/countBy";
import _filter from "lodash/filter";
import _every from "lodash/every";
import methods from "../lib/sharedComponentMethods";
import data from "../lib/sharedComponentData";
import api from "../config/api";
import TableHead from "../../templates/partials/TableHead.vue";
import TeamName from "../../templates/partials/TeamName.vue";
import Loader from "../../templates/partials/Loader.vue";
import Bonus from "../../templates/partials/Bonus.vue";
import ReportingValue from "../../templates/partials/ReportingValue.vue";

const report = {
  data() {
    return {
      msg: "Reporting",
      reporting: [],
      teamPhases: {},
      collapsibleInstance: null,
      displayTeams: false,
      sort: [],
      intervalId: null,
      actualPeriodIndex: null,
      isShortMode: false,
      isAutoSorted: false,
      ...data,
    };
  },
  beforeMount() {
    this.getPeriodIndex();
  },
  mounted() {
    const collapsibleElem = document.querySelector(".collapsible");
    Materialize.Collapsible.init(collapsibleElem, { accordion: false });
    this.collapsibleInstance =
      Materialize.Collapsible.getInstance(collapsibleElem);
    this.getReporting(true);
    this.intervalId = this.startPolling(["getReporting"]);
    this.isAutoSorted = false;
  },
  updated() {
    this.isActive();
    if (this.actualTeams.length > 0) {
      this.displayTeams = true;
    }
  },
  destroyed() {
    this.stopPolling(this.intervalId);
  },
  methods: Object.assign(
    {
      getReporting(full) {
        axios
          .get(
            `${api.getUrl()}/api/frontend/reporting/${full ? "?full=true" : ""}`
          )
          .then((response) => {
            if (response.data.message) {
              Materialize.toast({
                html: response.data.message,
                classes: "round red",
              });
            } else {
              if (response.data.report_type === "full") {
                if (response.data.is_short_mode) {
                  this.isShortMode = true;
                  this.setPeriods();
                }
                this.reporting = response.data.report;
              } else {
                this.reporting[response.data.report_index] =
                  response.data.report;
              }
              this.teamPhases = response.data.team_phases;
              this.percentageIterator();
              this.sortColumn(this.sort);
            }
            if (!this.isAutoSorted) {
              const defaultSortData = {
                periodId: this.actualPeriodIndex - 1,
                column: "rank",
                sort: "asc",
              };
              this.sortColumn(defaultSortData);
              this.isAutoSorted = true;
            }
          })
          .catch((err) => this.errors.push(err));
      },
      getPeriodIndex() {
        axios
          .get(`${api.getUrl()}/api/frontend/seminar`)
          .then((response) => {
            this.actualPeriodIndex = response.data.current_period_index;
            this.setPeriods();
          })
          .catch((e) => this.errors.push(e));
      },
      refreshReporting() {
        this.getReporting();
      },
      setPeriods() {
        this.periods = [];
        for (let i = 0; i < this.actualPeriodIndex; i += 1) {
          if (this.isShortMode) {
            if (i === 1) {
              console.log("Skipping period 2!");
            } else if (i >= 2) {
              this.periods.push({ id: i, title: `${i}. Spielrunde` });
            } else {
              this.periods.push({ id: i, title: `${i + 1}. Spielrunde` });
            }
          } else {
            this.periods.push({ id: i, title: `${i + 1}. Spielrunde` });
          }
        }
      },
      isActive() {
        this.collapsibleInstance.open(this.periods.length - 1);
      },
      isBonusActive(periodIndex) {
        // index is the array index, so 0 based
        if (
          this.actualPeriodIndex === periodIndex + 1 &&
          _every(Object.values(this.teamPhases), (p) => p === "Pause")
        ) {
          return "";
        }
        return "disabled";
      },
      isSorted(period, column) {
        if (this.sort.length > 0 && this.sort[period] !== undefined) {
          if (this.sort[period].column === column) {
            return "sorted-column";
          }
        }
        return "";
      },
      sortColumn(sortData) {
        const sd = sortData;
        let notLatestEntry = false;
        if (sd.column === "points") {
          notLatestEntry = true;
        }

        if (sd.length !== undefined) {
          this.sort.forEach((soD) => {
            if (soD !== undefined) {
              if (soD.column === "points") {
                notLatestEntry = true;
              }
              let tmpReport = _orderBy(
                this.reporting[soD.periodId],
                (lItem) => lItem.latestEntry[soD.column],
                [soD.sort]
              );
              if (notLatestEntry) {
                tmpReport = _orderBy(
                  this.reporting[soD.periodId],
                  (lItem) => lItem[soD.column],
                  [soD.sort]
                );
              }
              this.$set(this.reporting, soD.periodId, tmpReport);
            }
          });
        } else {
          const { periodId } = sd;
          const { column } = sd;
          const direction = sd.sort;
          const sortPeriod = this.reporting[sd.periodId];
          let countUndefined = _countBy(
            sortPeriod,
            (team) => team.latestEntry[column]
          );
          if (notLatestEntry) {
            countUndefined = _countBy(sortPeriod, (team) => team[column]);
          }
          if (
            countUndefined.undefined < 2 ||
            countUndefined.undefined === undefined
          ) {
            this.$refs[`sortable${periodId}`].forEach((child) => {
              if (column !== child.data.column) {
                child.setDefault();
              }
            });
            let tmpReport = _orderBy(
              this.reporting[periodId],
              (lItem) => lItem.latestEntry[column],
              [direction]
            );
            if (notLatestEntry) {
              tmpReport = _orderBy(
                this.reporting[periodId],
                (lItem) => lItem[column],
                [direction]
              );
            }
            _remove(tmpReport, (c) => c.realPoints === "NaN");
            this.$set(this.reporting, periodId, tmpReport);
            this.sort[periodId] = sortData;

            return true;
          }
        }
        return false;
      },
      showHideColumn(blendData) {
        if (blendData.column === "team") {
          const blendableTeams = this.$refs[`teamName${blendData.periodId}`];
          if (blendData.blend === "in") {
            blendableTeams.forEach((team) => team.blendIn());
          } else {
            blendableTeams.forEach((team) => team.blendOut());
          }
        } else {
          const blendableColumn = _filter(
            this.$refs[`teamValue${blendData.periodId}`],
            (column) => column.name === blendData.column
          );
          if (blendData.blend === "in") {
            blendableColumn.forEach((column) => column.blendIn());
          } else {
            blendableColumn.forEach((column) => column.blendOut());
          }
        }
      },
    },
    methods
  ),
  components: {
    "table-head": TableHead,
    "team-name": TeamName,
    loader: Loader,
    bonus: Bonus,
    "reporting-value": ReportingValue,
  },
};

export default report;
