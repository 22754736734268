import Loader from "../../templates/partials/Loader.vue";
import Pdf from "../lib/Pdf";
import TableHead from "../../templates/partials/TableHead.vue";
import TeamName from "../../templates/partials/TeamName.vue";
import _last from "lodash/last";
import _snakeCase from "lodash/snakeCase";
import api from "../config/api";
import axios from "../lib/AxiosClient";
import data from "../lib/sharedComponentData";
/* eslint-disable */
import fflBackgroundImage from "../../img/certificate/finance_heroes/certificate_bg.png";
import fflBackgroundImageFooter from "../../img/certificate/finance_heroes/logos_footer.png";
import fflBackgroundImagePicture from "../../img/certificate/finance_heroes/photo_bg.png";
import fflLogoTeamPicture from "../../img/certificate/finance_heroes/logo_photo.png";

import fontCertificate from "../../fonts/exports/certificate/SourceSansPro-Regular.base64";
import fontCertificateBold from "../../fonts/exports/certificate/SourceSansPro-Bold.base64";

import ghBackgroundImage from "../../img/certificate/giro_heroes/certificate_bg.png";
import ghBackgroundImageFooter from "../../img/certificate/giro_heroes/logos_footer.png";
import ghBackgroundImagePicture from "../../img/certificate/giro_heroes/photo_bg.png";
import ghLogoTeamPicture from "../../img/certificate/giro_heroes/logo_photo.png";
import helpers from "../lib/helpers";
import methods from "../lib/sharedComponentMethods";

import thBackgroundImage from "../../img/certificate/trading_heroes/certificate_bg.png";
import thBackgroundImageFooter from "../../img/certificate/trading_heroes/logos_footer.png";
import thBackgroundImagePicture from "../../img/certificate/trading_heroes/photo_bg.png";
import thLogoTeamPicture from "../../img/certificate/trading_heroes/logo_photo.png";

import yhBackgroundImage from "../../img/certificate/young_heroes/certificate_bg.png";
import yhBackgroundImageFooter from "../../img/certificate/young_heroes/logos_footer.png";
import yhBackgroundImagePicture from "../../img/certificate/young_heroes/photo_bg.png";
import yhLogoTeamPicture from "../../img/certificate/young_heroes/logo_photo.png";

/* eslint-enable */

const certificate = {
  name: "Certificate",
  props: {
    seminarType: {
      type: String,
      default: "giro_heroes",
    },
  },
  data() {
    return Object.assign(
      {
        msg: {
          title: "Zertifikate",
          team: "Team",
          download: "Team-Zertifikat herunterladen",
          downloadImage: "Teambild herunterladen",
          lastUpdate: "letztes Update",
          downloadAll: "Alle Zertifikate herunterladen",
          downloadAllImages: "Alle Teambilder herunterladen",
          certificate: "Zertifikat",
          changeFooter: "Standard",
        },
        reporting: [],
        lastPeriodData: [],
        pdf: {
          loaded: false,
          image: null,
          base64CertificateBackground: null,
          base64PictureBackground: null,
          base64Footer: null,
          footerImage: new Image(),
          base64Logo: null,
          logoImage: new Image(),
          usePreprint: false,
        },
        selectedFile: null,
        attachedFile: null,
        cert: null,
        doc: null,
        loading: [],
        loads: false,
        schoolName: null,
        schoolClass: null,
        footerImages: {
          finance_heroes: fflBackgroundImageFooter,
          giro_heroes: ghBackgroundImageFooter,
          trading_heroes: thBackgroundImageFooter,
          young_heroes: yhBackgroundImageFooter,
        },
        certificateBackground: {
          finance_heroes: fflBackgroundImage,
          giro_heroes: ghBackgroundImage,
          trading_heroes: thBackgroundImage,
          young_heroes: yhBackgroundImage,
        },
        logoTeamPicture: {
          finance_heroes: fflLogoTeamPicture,
          giro_heroes: ghLogoTeamPicture,
          trading_heroes: thLogoTeamPicture,
          young_heroes: yhLogoTeamPicture,
        },
        backgroundImagePicture: {
          finance_heroes: fflBackgroundImagePicture,
          giro_heroes: ghBackgroundImagePicture,
          trading_heroes: thBackgroundImagePicture,
          young_heroes: yhBackgroundImagePicture,
        },
      },
      data
    );
  },
  mounted() {
    this.getReporting();
    this.preLoadPdfImage();
    this.getSchoolInfo();
  },
  methods: Object.assign(
    {
      handleFileChange(e) {
        const reader = new window.FileReader();
        reader.onload = (event) => {
          this.attachedFile = event.target.result;
        };
        this.selectedFile = e.target.files[0];
        if (this.selectedFile) {
          reader.readAsDataURL(this.selectedFile);
        } else {
          this.attachedFile = null;
        }
      },
      getReporting() {
        axios
          .get(`${api.getUrl()}/api/frontend/reporting/?full=true`)
          .then((response) => {
            this.reporting = response.data.report;
            this.percentageIterator();
            this.lastPeriodData = _last(this.reporting);
            this.setLoad();
          })
          .catch((err) => this.errors.push(err));
      },
      getSchoolInfo() {
        axios
          .get(`${api.getUrl()}/api/frontend/seminar`)
          .then((response) => {
            this.schoolClass = response.data.school_class;
            this.schoolName = response.data.school;
          })
          .catch((err) => this.errors.push(err));
      },
      setLoad() {
        this.loads = false;
        for (let i = 0; i < this.lastPeriodData.length; i += 1) {
          this.$set(this.loading, i, false);
        }
      },
      exportTeamCertificate(index) {
        this.getTeamData(index, "generateCertificatePdf");
      },
      getTeamData(index, callback) {
        this.loads = true;
        this.$set(this.loading, index, true);
        const teamId = this.lastPeriodData[index].id;
        axios
          .get(`${api.getUrl()}/api/frontend/team/${teamId}`)
          .then((team) => {
            this[callback]([this.addTeamInfoToReporting(team, index)]);
          })
          .catch((err) => this.errors.push(err));
      },
      addTeamInfoToReporting(response, index) {
        const pdfData = this.lastPeriodData[index];
        pdfData.teamMembers = response.data.members;
        // const teamPicture = response.data.image;
        // pdfData.teamPicture = teamPicture;
        return pdfData;
      },
      exportAllCertificate() {
        this.exportAll("generateCertificatePdf");
      },
      exportAll(callback) {
        const allTeams = this.reporting.slice(-1)[0];
        const promises = [];
        const pdfDataArray = [];

        allTeams.forEach((team, index) => {
          this.loads = true;
          this.$set(this.loading, index, true);
          promises.push(
            axios.get(`${api.getUrl()}/api/frontend/team/${team.id}`)
          );
        });

        axios
          .all(promises)
          .then((results) => {
            results.forEach((team, index) => {
              pdfDataArray.push(this.addTeamInfoToReporting(team, index));
            });
            this[callback](pdfDataArray);
          })
          .catch((err) => this.errors.push(err));
      },
      generateCertificatePdf(pdfData) {
        const teamCount = pdfData.length;
        let postfix = "all";
        this.cert = new Pdf();
        this.cert.addFont(fontCertificate, "certificate", "normal");
        this.cert.addFont(fontCertificateBold, "certificate", "bold");
        const footerImage = this.attachedFile || this.pdf.base64Footer;
        pdfData.forEach((teamInfo, teamIndex) => {
          const percentage = teamInfo.pointsPercentage;
          const today = new Date(); // defaults to NOW and not the date of the seminar
          const name = teamInfo.teamName;
          const members = teamInfo.teamMembers;
          const memberCount = members.length;
          members.forEach((member, index) => {
            const firstName = member.first_name.toLocaleUpperCase();
            const lastName = member.last_name.toLocaleUpperCase();
            if (!this.pdf.usePreprint) {
              this.cert.addBackgroundImage(
                this.pdf.base64CertificateBackgroud,
                "PNG"
              );
            }
            this.cert.textOffset = 93;
            this.cert.setTextColor(0, 184, 212);
            this.cert.setFont("certificate", "normal");
            this.cert.addText(
              `${this.schoolName}, ${today.toLocaleDateString()} `,
              "normal",
              "center",
              8.5
            );
            this.cert.textOffset = 101;
            this.cert.setFont("certificate", "bold");
            this.cert.addText(
              `${firstName} ${lastName}`,
              "normal",
              "center",
              18
            );
            this.cert.textOffset = 186;
            this.cert.addText(
              `${firstName} ${lastName}`,
              "normal",
              "center",
              13
            );
            this.cert.textOffset = 198;
            this.cert.addText(
              `${name.toLocaleUpperCase()}`,
              "normal",
              "center",
              13
            );
            this.cert.textOffset = 215;
            this.cert.addText(`${percentage}%`, "normal", "center", 23);
            this.cert.addImage(footerImage, "PNG", 12.5, 280, 185, 10);
            if (teamIndex < teamCount - 1 || index < memberCount - 1) {
              this.cert.addPage();
            }
          });
          if (teamCount === 1) {
            postfix = _snakeCase(name);
          }
        });
        this.cert.save(`Heroes_Zertifikat_${postfix}`);
        this.setLoad();
      },
      preLoadPdfImage() {
        if (this.pdf.image === null) {
          const backgroundCertificate = new Image();

          backgroundCertificate.onload = () => {
            const canvas = document.createElement("canvas");
            canvas.width = backgroundCertificate.naturalWidth;
            canvas.height = backgroundCertificate.naturalHeight;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(backgroundCertificate, 0, 0);
            this.pdf.base64CertificateBackgroud = canvas.toDataURL("image/png");
          };

          this.pdf.footerImage.onload = () => {
            const canvas = document.createElement("canvas");
            canvas.width = this.pdf.footerImage.naturalWidth;
            canvas.height = this.pdf.footerImage.naturalHeight;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(this.pdf.footerImage, 0, 0);
            this.pdf.base64Footer = canvas.toDataURL("image/png");
          };

          const backgroundPicture = new Image();

          backgroundPicture.onload = () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            const w = backgroundPicture.width;
            const ratio = backgroundPicture.height / w;
            const h = backgroundPicture.height / ratio;
            canvas.width = w;
            canvas.height = h;
            ctx.translate(h, 0);
            ctx.rotate((90 * Math.PI) / 180);
            ctx.drawImage(backgroundPicture, 0, 0, w, h);

            this.pdf.base64PictureBackground = canvas.toDataURL("image/png");
          };

          this.pdf.logoImage.onload = () => {
            const canvas = document.createElement("canvas");
            canvas.width = this.pdf.logoImage.naturalWidth;
            canvas.height = this.pdf.logoImage.naturalHeight;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(this.pdf.logoImage, 0, 0);
            this.pdf.base64Logo = canvas.toDataURL("image/png");
          };

          backgroundCertificate.src =
            this.certificateBackground[this.seminarType];
          this.pdf.footerImage.src = this.footerImages[this.seminarType];
          backgroundPicture.src = this.backgroundImagePicture[this.seminarType];
          this.pdf.logoImage.src = this.logoTeamPicture[this.seminarType];
        }
      },
    },
    methods
  ),
  components: {
    "table-head": TableHead,
    "team-name": TeamName,
    loader: Loader,
  },
};

export default certificate;
