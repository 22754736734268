<template>
  <div
    class="team-table"
  >
    <div
      v-if="showTable"
    >
      <a
        class="team-link left-icon"
        @click="toggleTable"
      >
        {{ team }} <i class="fa fa-eye-slash" />
      </a>
      <table class="table table-bordered">
        <thead>
          <tr>
            <th>Aktie</th>
            <th>Wert</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(stockValue, stockName) in stocks"
            :key="stockName.id"
          >
            <td>
              {{ stockName }}
            </td>
            <td>
              <span
                v-bind:class="getClass(stockValue)"
              >
                {{ stockValue | percentage }} %
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <a
      v-else
      class="team-link left-icon"
      @click="toggleTable"
    >
      {{ team }} <i class="fa fa-eye" />
    </a>
  </div>
</template>

<script>

export default {
  filters: {
    percentage(value) {
      return value.toFixed(2);
    }
  },
  props: {
    stocks: {
      type: Object,
      default: null
    },
    team: {
      type: String,
      default: "",
    },
    options: {
      type: Object,
      default: null
    },
  },
  data: () => ({
    showTable: false,
  }),
  methods: {
    getClass(value) {
      if(value > 0) {
        return "success-text"
      } else {
        return "warning-text"
      }
    },
    toggleTable () {
      this.showTable = !this.showTable
    },
  }
};
</script>
<style>
  .team-table {
    padding: 15px;
    border-top: 1px solid rgba(100, 100, 100, 0.1);
  }

  .success-text {
    color: green;
  }

  .warning-text {
    color: red;
  }
</style>
