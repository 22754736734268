import _replace from 'lodash/replace'
import Loader from '../../../templates/partials/Loader.vue'
import data from '../../lib/sharedComponentData'

const reportingValue = {
  name: 'ReportingValue',
  props: ['name', 'value', 'valuePercentage', 'realPoints', 'seminar'],
  data () {
    return Object.assign({
      showValue: true
    }, data)
  },
  methods: {
    blendIn () {
      this.showValue = true
    },
    blendOut () {
      this.showValue = false
    },
    notUndefined (value) {
      if (value === undefined) {
        return false
      }
      return true
    },
    replacePoint (value) {
      return _replace(value.toString(), '.', ',')
    }
  },
  components: {
    loader: Loader
  }
};

export default reportingValue
