import Materialize from "materialize-css";
import api from "../../config/api";
import axios from "axios";
import data from "../../lib/sharedComponentData";
import methods from "../../lib/sharedComponentMethods";

const bonus = {
  data() {
    return {
      ...data,
      modalInstance: null,
      defaultBonus: 20,
      seminarType: document
        .querySelector("[seminar-type]")
        .getAttribute("seminar-type"),
      error: [],
      teamId: "",
      value: "",
      isLoading: false, // Added isLoading property
    };
  },
  name: "bonus",
  props: ["currentReport", "periodId", "activeButtons"],
  methods: {
    ...methods,
    setBonusPoints(propertyName, value = this.defaultBonus, team = null) {
      this.isLoading = true; // Set isLoading to true when button is clicked
      axios
        .put(`${api.getUrl()}/api/frontend/period/${this.periodId}/bonus`, {
          name: propertyName,
          value,
          team,
        })
        .then((res) => {
          let toastMessage = null;
          if (res.data === "") {
            toastMessage = {
              html: "Da ist etwas schief gelaufen.",
              classes: "round red",
            };
          } else if (res.data.status === "error") {
            toastMessage = { html: res.data.message, classes: "round red" };
          } else {
            toastMessage = { html: res.data.message, classes: "round green" };
          }
          this.$emit("refresh");
          Materialize.toast(toastMessage);
        })
        .catch((err) => Materialize.toast({ html: err, classes: "round red" }))
        .finally(() => {
          this.isLoading = false; // Set isLoading to false when request is finished
        });
    },
    setBonusManually(periodIndex) {
      if (this.teamId === "") {
        Materialize.toast({
          html: "Kein Team ausgewählt.",
          classes: "round red",
        });
        return false;
      }

      if (this.value === "") {
        Materialize.toast({
          html: "Kein Wert gesetzt",
          classes: "round red",
        });
        return false;
      }
      this.modalInstance.close();
      this.setBonusPoints("manually", this.value, this.teamId);
      return true;
    },
    openBonusManually() {
      const manuallyModal = document.querySelector(
        `.bonus-manually-${this.periodId}`
      );
      const teamSelect = manuallyModal.querySelector("select");
      Materialize.FormSelect.init(teamSelect);
      this.modalInstance = Materialize.Modal.init(manuallyModal);
      this.modalInstance.open();
    },
  },
};

export default bonus;
