import Router from "../router";
import logoBH from "../../img/certificate/trading_heroes/logo_photo.png";
import logoFH from "../../img/certificate/finance_heroes/logo_photo.png";
import logoGH from "../../img/certificate/giro_heroes/logo_photo.png";
import logoYH from "../../img/certificate/young_heroes/logo_photo.png";
import HeroesLogo from "../../img/HeroesFinanzbildung-Logo.svg";

export default {
  name: "MainMenu",
  props: {
    seminarType: {
      type: String,
      default: "",
    },
    setLoginState: Function,
  },
  data() {
    return {
      navLinks: this.showNavLinks(),
      image: this.getImageLink(),
    };
  },
  watch: {
    seminarType: function (val) {
      this.navLinks = this.showNavLinks();
      this.image = this.getImageLink();
    },
  },
  methods: {
    showNavLinks() {
      if (this.seminarType) {
        return Router.options.routes
          .filter((route) => {
            if (route.meta && !route.meta.hide) {
              return route;
            }
          })
          .filter((route) => {
            if (
              route.meta &&
              (!route.meta.seminar ||
                route.meta.seminar.includes(this.seminarType))
            ) {
              return route;
            }
          });
      }
      return Router.options.routes.filter(
        (route) => route.name === "Ausloggen"
      );
    },
    getImageLink() {
      switch (this.seminarType) {
        case "giro_heroes":
          return logoGH;
        case "finance_heroes":
          return logoFH;
        case "trading_heroes":
          return logoBH;
        case "young_heroes":
          return logoYH;
        default:
          return HeroesLogo;
      }
    },
  },
};
