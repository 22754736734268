import JsPdf from 'jspdf'

const DPI = 72;
const INCH_IN_MM = 25.4;

const transformDotsToMm = dots => (dots / DPI) * INCH_IN_MM;

export default class Pdf {
  constructor (orientation = 'portrait') {
    this._pdf = new JsPdf(orientation);
    this.pageNumber = 1;
    this.orientation = orientation;
    this.verticalPadding = 20;
    this.horizontalPadding = 20;
    this.textOffset = null;
    this.documentFontSize = 14;
    this.footerFontSize = 10;
    this.addFooter()
    // this._pdf.setFont('Helvetica');
  }

  getPageHeight () {
    return this._pdf.internal.pageSize.getHeight()
  }

  getPageWidth () {
    return this._pdf.internal.pageSize.getWidth()
  }

  getMaxLineWidth () {
    return this.getPageWidth() - (this.horizontalPadding * 2)
  }

  getLineHeight () {
    return transformDotsToMm(this._pdf.internal.getLineHeight())
  }

  getMaxLinesOnCurrentPage () {
    return Math.floor((this.getPageHeight() - this.textOffset - this.verticalPadding) / this.getLineHeight())
  }

  getMaxLinesOnFullPage () {
    return Math.floor((this.getPageWidth() - (this.verticalPadding * 2)) / this.getLineHeight())
  }

  addText (text, style = 'normal', align = 'left', fontSize = 14) {
    this._pdf.setFontSize(fontSize);
    const lineHeight = this.getLineHeight();

    if (this.textOffset === null) {
      this.textOffset = this.verticalPadding + lineHeight
    }

    this._pdf.setFontStyle(style);

    let maxLines = this.getMaxLinesOnCurrentPage();
    let lines = this._pdf.splitTextToSize(text, this.getMaxLineWidth());

    if (lines.length > maxLines) {
      const maxLinesFull = this.getMaxLinesOnFullPage();
      let linesPerPage = null;

      while (lines.length > 0) {
        linesPerPage = lines.splice(0, maxLines);

        linesPerPage.forEach((line) => {
          const distanceFromLeft = this.getMargin(line, align) + this.horizontalPadding;
          this._pdf.text(line, distanceFromLeft, this.textOffset);
          this.textOffset += lineHeight
        });

        const remainingLength = lines.length;

        maxLines = maxLinesFull;
        if (maxLines > remainingLength) {
          maxLines = remainingLength
        }

        if (remainingLength > 0) {
          this.addPage();
          this.textOffset = this.verticalPadding
        }
      }
      lines = linesPerPage
    } else {
      lines.forEach((line) => {
        const distanceFromLeft = this.getMargin(line, align) + this.horizontalPadding;
        this._pdf.text(line, distanceFromLeft, this.textOffset);
        this.textOffset += lineHeight
      })
    }

    return this
  }

  getMargin (line, align) {
    const wordMargin = this.getMaxLineWidth() - this._pdf.getTextWidth(line);
    if (align === 'right') {
      return wordMargin
    }
    if (align === 'center') {
      return wordMargin / 2
    }
    return 0
  }

  save (fileName = 'dokument') {
    this._pdf.save(`${fileName}.pdf`);
    return this
  }

  addPage () {
    this._pdf.addPage();
    this.addFooter();
    this.textOffset = null;
    return this
  }

  addFooter () {
    return this
  }

  addBackgroundImage (image, type) {
    this._pdf.addImage(image, type, 0, 0, this.getPageWidth(), this.getPageHeight(), undefined, 'FAST')
  }

  addImage (image, type, x, y, width, height) {
    this._pdf.addImage(image, type, x, y, width, height, undefined, 'FAST')
  }

  addFreeText (text, distanceFromLeft, distanceFromTop, style = 'normal', fontSize = 14) {
    this._pdf.setFontSize(fontSize);
    this._pdf.setFontStyle(style);
    this._pdf.text(text, distanceFromLeft, distanceFromTop);
    return this
  }

  text (text, distanceFromLeft, distanceFromTop) {
    this._pdf.text(text, distanceFromLeft, distanceFromTop);
    return this
  }

  setTextColor (r, g, b) {
    this._pdf.setTextColor(r, g, b)
  }

  setFontSize (fontSize) {
    this._pdf.setFontSize(fontSize)
  }

  getTextWidth (text) {
    return this._pdf.getTextWidth(text)
  }

  addFont (source, name, weight) {
    this._pdf.addFileToVFS(`${name}.ttf`, source);
    return this._pdf.addFont(`${name}.ttf`, name, weight)
  }

  setFont (name, weight) {
    return this._pdf.setFont(name, weight)
  }

  getFontList () {
    return this._pdf.getFontList()
  }
}
