<template>
  <div class="jumbotron bg-white mt-2">
    <div
      class="container depot-container"
    >
      <div class="col-md-12">
        <div
          v-if="loaded"
          class="thumbnail depot-chart-container"
        >
          <div class="caption">
            <div class="top-container">
              <h3>Kursverläufe</h3>
              <div
                v-if="lastPeriod"
                class="dropdown"
              >
                <button
                  class="dropbtn btn"
                  @click="showTimeLineDropdown"
                >
                  Zeitraum
                </button>
                <div
                  id="timeLineDropdown"
                  class="dropdown-content"
                >
                  <a @click="setTimelineData(26, false)">6 Monate</a>
                  <a @click="setTimelineData(52, false)">1 Jahr</a>
                  <a @click="setTimelineData(260, false)">5 Jahre</a>
                  <a @click="setTimelineData(520, false)">10 Jahre</a>
                  <a @click="setTimelineData(2496, true)">Gesamt</a>
                </div>
              </div>
            </div>
            <LineChart
              :chartdata="chartData"
              :handle-click="handleLegendClick"
              :key="refreshTick"
              :legend-data="legendData"
              :options="options"
              class="line-chart"
            />
          </div>
          <h2>Top 3 Aktien Übersicht</h2>
          <div
            v-for="(stocks, team) in topStocks"
            :key="team.id"
          >
            <TeamStockTable
              :stocks="stocks"
              :team="teamNames[team]"
            />
          </div>
        </div>
        <div
          v-else
          class="spinner-container"
        >
          <pulse-loader color="#1c4587" />
          <br>
          Daten werden geladen.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import { setIntervalAsync } from 'set-interval-async/dynamic'

import api from "../js/config/api";
import LineChart from './partials/Chart.vue'
import TeamStockTable from './partials/TeamStockTable'

let firstYear = 0;
let veryFirstYear = new Date().getFullYear();
let p4GlobalTimespan = 2496;
let p4GlobalBool = false;

export default {
  name: "GraphContainer",
  components: {
    LineChart,
    PulseLoader,
    TeamStockTable
  },
  data: () => ({
    loaded: false,
    refreshTick: 0,
    chartData: null,
    legendData: [],
    topStocks: null,
    teamNames: null,
    lastPeriod: false,
    options: {
      elements: {
        point: {
          radius: 0,
        }
      },
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        xAxes:[{
          ticks:{
              display: true,
              autoSkip: true,
              maxTicksLimit: 1
          }
        }],
        yAxes:[{
          ticks:{
            callback: function (value) {
              return value + '%';
            },
          }
        }]
      }
    },
  }),
  async mounted() {
    const data = (await this.getChartData()).data;
    if (data["currentPeriodIndex"] >= 4) {
      p4GlobalTimespan = (data["currentYear"] - veryFirstYear) * 52 + 26;
      p4GlobalBool = true;
    } else {
      p4GlobalTimespan = data["worldData"].length;
    }
    await this.loadData(p4GlobalTimespan, p4GlobalBool);
    if(this.chartData) {
      this.legendData = this.chartData.datasets.map(() => false);
      setIntervalAsync(async () => {
        await this.loadData(p4GlobalTimespan, p4GlobalBool);
      }, 15000);
    }
    // Close the dropdown if the user clicks outside of it
    window.onclick = function(event) {
      if (!event.target.matches('.dropbtn')) {
        let dropdowns = document.getElementsByClassName("dropdown-content");
        let i;
        for (i = 0; i < dropdowns.length; i++) {
          let openDropdown = dropdowns[i];
          if (openDropdown.classList.contains('show')) {
            openDropdown.classList.remove('show');
          }
        }
      }
    }
  },
  methods: {
    /* When the user clicks on the button,
    toggle between hiding and showing the dropdown content */
    showTimeLineDropdown() {
      document.getElementById("timeLineDropdown").classList.toggle("show");
    },
    handleLegendClick(index) {
      this.legendData[index] = !this.legendData[index]
    },
    async setTimelineData(p4Timespan, p4Bool) {
      p4GlobalTimespan = p4Timespan;
      p4GlobalBool = p4Bool;
      await this.loadData(p4Timespan, p4Bool);
    },
    async getChartData() {
      return await axios.get(`${api.getUrl()}/api/frontend/depot-graph/`);
    },
    async loadData(p4Timespan, p4Bool) {
      try {
        const data = (await this.getChartData()).data;
        this.topStocks = data['topStocks'];
        this.teamNames = data['teamNames'];
        let chartData = {
          labels: [],
          datasets: []
        };

        // In case we have more than 18 teams
        function generateRandomTeamColor() {
          // get first alphabet in upper case
          const firstAlphabet = name.charAt(0).toLowerCase();

          // get the ASCII code of the character
          const asciiCode = firstAlphabet.charCodeAt(0);

          // number that contains 3 times ASCII value of character -- unique for every alphabet
          const colorNum = asciiCode.toString() + asciiCode.toString() + asciiCode.toString();

          let num = Math.round(0xffffff * parseInt(colorNum));
          let r = num >> 16 & 255;
          let g = num >> 8 & 255;
          let b = num & 255;

          return 'rgb(' + r + ', ' + g + ', ' + b + ', 0.3)'
        }

        // This colors are used for the first 18 teams
        let colorPresets =
          [ "#1c4587",
            "#c6438b",
            "#ffe500",
            "#4cd3de",
            "#b48af9",
            "#362C28",
            "#ff8138",
            "#604895",
            "#82d9ca",
            "#ffa600",
            "#00c9f5",
            "#e467d1",
            "#ea4a76",
            "#22baff",
            "#974696",
            "#73a6ff",
            "#EDFF86",
            "#1B998B",
             ];

        function generateTeamColors(teamId) {
          if(teamId <= colorPresets.length) {
            return colorPresets[teamId]
          } else {
            return generateRandomTeamColor()
          }
        }

        function normalizeData(data) {
          const firstValue = data[0];
          return data.map(value => value - firstValue);
        }

        // We need the "first" year of period 4
        const currentYear = data["currentYear"];
        // So if we start period 4 we set the "first" year and only then, we dont want to update it all the time
        if(p4Bool) {
          firstYear = veryFirstYear;
        } else {
          if(p4Timespan == 26) {
            firstYear = currentYear;
          } else {
            firstYear = Math.max(Math.floor(currentYear - p4Timespan/52), veryFirstYear);
          }
        }

        if(p4Bool) {
          //Add 26 weeks to the timespan because it starts in the middle of the year...
          p4Timespan = (currentYear - firstYear) * 52 + 26;
        } else if (data["currentPeriodIndex"] < 4){
          p4Timespan = data["worldData"].length;
        }

        let finalYear = 0

        if(p4Bool || (data["currentPeriodIndex"] < 4)) {
          finalYear = firstYear + Math.round(p4Timespan/52);
        } else {
          finalYear = currentYear;
        }

        if(data["dataLoaded"]) {
          // In Period 4 we display the data of 48 incoming years (in total 2496 stock data),
          // so with each time skip we update 52 new stock data at once, but we only want to display the first year
          // in which the period 4 started and the last year the player will see and in between those we want to display
          // our graph, for the other periods we just display the graph for one year
          if (data["currentPeriodIndex"] >= 4) {
            this.lastPeriod = true;
          }

          if(p4Bool || (data["currentPeriodIndex"] < 4)) {
            chartData["labels"] = [firstYear, ...Array(p4Timespan).fill(""), finalYear - 1];
          } else {
            chartData["labels"] = [firstYear, ...Array(p4Timespan).fill(""), finalYear];
          }

          chartData["datasets"].push({
            label: "Gesamt-Aktienindex",
            data: normalizeData(data["worldData"].slice(Math.max(data["worldData"].length - p4Timespan, 0))),
            backgroundColor: "transparent",
            borderColor: "#F8434C",
            pointBackgroundColor: "#fff",
            lineTension: 0,
            borderWidth: 5,
          });

          chartData["datasets"].push({
            label: "Teamdurchschnitt",
            data: normalizeData(data["meanData"].slice(Math.max(data["meanData"].length - p4Timespan, 0))),
            backgroundColor: "transparent",
            borderColor: "#00B34A",
            pointBackgroundColor: "#fff",
            lineTension: 0,
            borderDash: [5, 10],
            borderWidth: 5,
            borderCapStyle: 'round',
          });

          Object.keys(data["teamData"]).forEach((team, index) => {
            chartData["datasets"].push({
              label: data["teamNames"][team],
              data: normalizeData(data["teamData"][team].slice(Math.max(data["teamData"][team].length - p4Timespan, 0))),
              backgroundColor: "transparent",
              borderColor: generateTeamColors(index),
              pointBackgroundColor: "#fff",
              lineTension: 0,
            })
          });

          this.chartData = chartData;
          this.loaded = true;
          this.refreshTick++;
        }
      } catch(e) {
        console.log(e);
      }
    },
  }
};
</script>

<style scoped>
  .depot-container {
    margin-top: 1em;
    margin-bottom: 1em;
  }
  .depot-chart-container {
    background: #fff;
    border: 1px solid #b5b5b5;
    border-radius: 5px;
    -webkit-box-shadow: 0 3px 11px -6px rgba(0,0,0,0.75);
    -moz-box-shadow: 0 3px 11px -6px rgba(0,0,0,0.75);
    box-shadow: 0 0 11px -5px rgba(0,0,0,0.75);
  }
  .line-chart {
    padding: 10px;
  }

  .spinner-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .top-container {
    display: flex;
    align-items: center;
  }

  .dropbtn:hover, .dropbtn:focus {
    background-color: #678999;
  }

  .dropdown {
    position: relative;
    display: inline-block;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    overflow: auto;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }

  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    cursor: pointer;
  }

  .dropdown a:hover {
    background-color: #ddd;
  }

  .show {
    display: block;
    opacity: 1;
    margin-top: 50px;
  }
</style>
