import AddTeam from "../../templates/AddTeam.vue";
import Loader from "../../templates/partials/Loader.vue";
import LocalStorageService from "../lib/LocalStorageService";
import Materialize from "materialize-css";
import Multiselect from "vue-multiselect";
import Overlay from "../../templates/partials/Overlay.vue";
import TableHead from "../../templates/partials/TableHead.vue";
import _words from "lodash/words";
import api from "../config/api";
import axios from "../lib/AxiosClient";
import data from "../lib/sharedComponentData";
import methods from "../lib/sharedComponentMethods";
import QrcodeVue from "qrcode.vue";

const state = {
  props: {
    setSeminarType: {
      type: Function,
      default: () => {},
    },
  },
  name: "State",
  data() {
    return Object.assign(
      {
        title: "Aktuelles Seminar",
        nothingFound: "Keine Seminare gefunden.",
        fields: {
          name: { label: "Team", sortable: true, class: "text-center" },
        },
        seminar: [],
        actualTeam: {},
        teamsExist: false,
        startSeminarDisabled: true,
        isSeminarStarted: false,
        isMecodiaSeminar: false,
        nextPeriodDisabled: true,
        isLastPeriod: false,
        seminarFound: false,
        intervalId: null,
        showTeams: false,
        activateTeams: false,
        removeTeams: false,
        renameTeams: false,
        gameSelected: {
          name: "Bitte Spiel auswählen...",
          value: undefined,
          $isDisabled: true,
        },
        modeSelected: {
          name: "Ohne zusätzlichen Modus",
          value: "NoMode",
          $isDisabled: true,
        },
        types: [],
        modes: [
          { name: "Erweiteter Modus", value: "extendedMode" },
          { name: "Verkürzter Modus", value: "shortMode" },
          { name: "Ohne zusätzlichen Modus", value: "NoMode" },
        ],
        value: "",
        size: 300,
      },
      data
    );
  },
  mounted() {
    if (window.location.href.includes("localhost")) {
      this.isMecodiaSeminar = true;
    }

    if (LocalStorageService.getAccessToken()) {
      this.setCodeData();
      this.getActiveSeminar();
      this.setDropdownChoices();
      this.value = LocalStorageService.getCode();
      this.intervalId = this.startPolling(["getActiveSeminar"]);
      const modalGetSchool = document.querySelector("#modalGetSchool");
      Materialize.Modal.init(modalGetSchool);
      this.gameSelected = {
        name: "Bitte wählen...",
        value: undefined,
        $isDisabled: true,
      };
      const modalNextPeriod = document.querySelector("#modalNextPeriod");
      Materialize.Modal.init(modalNextPeriod);
    }
  },
  updated() {
    const tooltipsTeam = document.querySelectorAll(".tooltipped-team");
    const tooltipsDelete = document.querySelectorAll(".tooltipped-delete");
    const tooltipsRename = document.querySelectorAll(".tooltipped-rename");

    if (
      this.seminar.accepting_registration !== undefined &&
      !this.seminar.accepting_registration
    ) {
      this.isSeminarStarted = true;
    }

    Materialize.Tooltip.init(tooltipsTeam, {
      html: "Teammitglieder",
      position: "right",
    });
    Materialize.Tooltip.init(tooltipsDelete, {
      html: "Team löschen",
      position: "right",
    });
    Materialize.Tooltip.init(tooltipsRename, {
      html: "Team umbenennen",
      position: "right",
    });
  },
  destroyed() {
    window.clearInterval(this.intervalId);
  },
  methods: Object.assign(
    {
      setCodeData() {
        this.seminar.seminar_type = LocalStorageService.getSeminarType();
        if (this.seminar.seminar_type === "trading_heroes") {
          this.gameSelected.name = "Börsen Heroes";
          this.gameSelected.value = "trading_heroes";
        } else if (this.seminar.seminar_type === "finance_heroes") {
          this.gameSelected.name = "Finance Heroes";
          this.gameSelected.value = "finance_heroes";
        } else if (this.seminar.seminar_type === "young_heroes") {
          this.gameSelected.name = "Young Heroes";
          this.gameSelected.value = "young_heroes";
        } else {
          this.gameSelected.name = "Giro Heroes";
          this.gameSelected.value = "giro_heroes";
        }
      },
      setDropdownChoices() {
        if (LocalStorageService.getSpecialUser()) {
          this.types.push(
            { name: "Bitte wählen...", value: undefined, $isDisabled: true },
            { name: "Giro Heroes", value: "giro_heroes" },
            { name: "Börsen Heroes", value: "trading_heroes" },
            { name: "Finance Heroes", value: "finance_heroes" },
            { name: "Young Heroes", value: "young_heroes" }
          );
        } else {
          if (this.seminar.seminar_type === "trading_heroes") {
            this.types.push({ name: "Börsen Heroes", value: "trading_heroes" });
          } else if (this.seminar.seminar_type === "finance_heroes") {
            this.types.push({
              name: "Finance Heroes",
              value: "finance_heroes",
            });
          } else if (this.seminar.seminar_type === "young_heroes") {
            this.types.push({ name: "Young Heroes", value: "young_heroes" });
          } else {
            this.types.push({ name: "Giro Heroes", value: "giro_heroes" });
          }
        }
      },
      getActiveSeminar() {
        axios
          .get(`${api.getUrl()}/api/frontend/seminar`)
          .then((response) => {
            this.seminar = response.data;
            this.actualPeriodIndex = response.data.current_period_index;

            if (typeof this.seminar !== "object") {
              console.log("Kein aktives Seminar gefunden!");
              this.setSeminarType("");
              return;
            }

            //CHANGE HERE IF NUMBER OF TOTAL PERIODS IN ANY APP CHANGES
            // Trading heroes has some pauses in between of period 4, so we play a total of seven rounds instead of the usual 4
            if (this.seminar.seminar_type === "trading_heroes") {
              this.lastPeriod = 7;
            }
            //GiroHeroes period 4 is now split into two periods, so we play a total of 5 rounds
            if (this.seminar.seminar_type === "giro_heroes") {
              this.lastPeriod = 5;
            }

            //FinanceHeroes has 4 periods
            if (this.seminar.seminar_type === "finance_heroes") {
              this.lastPeriod = 4;
            }
            //YoungHeroes has 3 periods
            if (this.seminar.seminar_type === "young_heroes") {
              this.lastPeriod = 3;
            }

            if (this.seminar.id) {
              this.seminarFound = true;
              const seminarType = this.types.find(
                (type) => this.seminar.seminar_type === type.value
              ).value;
              this.setSeminarType(seminarType);
            }

            if (!this.seminar.accepting_registration) {
              this.startSeminarDisabled = true;
            }
            if (
              this.seminar.teams.length > 0 &&
              this.seminar.accepting_registration
            ) {
              this.startSeminarDisabled = false;
            }

            if (this.seminar.teams.length > 0) {
              this.teamsExist = true;
              this.checkNextPeriod();
            }
          })
          .catch((e) => {
            this.errors.push(e);
          });
      },
      getLastItem(array) {
        if (typeof array !== "object" || array.length === 0) {
          return null;
        }
        return array[array.length - 1];
      },
      getLastPeriod(team, periodItem) {
        return team.period;
      },
      checkNextPeriod() {
        let nextPeriod = false;
        let countTeamsEnd = 0;
        const { teams } = this.seminar;
        const teamCount = teams.length;

        teams.forEach((team) => {
          const teamPeriodIndex = team.period.period_index;
          if (this.actualPeriodIndex < teamPeriodIndex) {
            this.actualPeriodIndex = teamPeriodIndex;
          }

          if (
            this.actualPeriodIndex === teamPeriodIndex &&
            team.period.phase === "Pause"
          ) {
            countTeamsEnd += 1;
          }
        });
        nextPeriod =
          countTeamsEnd >= teamCount &&
          this.actualPeriodIndex < this.lastPeriod;

        this.nextPeriodDisabled = !nextPeriod;
        this.isLastPeriod = this.actualPeriodIndex >= this.lastPeriod;
      },
      getSchool() {
        const selectedGameOption = this.gameSelected.value;
        const selectedModeOption = this.modeSelected.value;
        const school = document.getElementById("school").value;
        const schoolClass = document.getElementById("schoolClass").value;
        this.nextPeriodDisabled = true;

        if (selectedGameOption === undefined) {
          Materialize.toast({
            html: "Kein Seminar ausgewählt.",
            classes: "round red",
          });
          return;
        }

        if (_words(school).length === 0) {
          Materialize.toast({
            html: "Kein Schulname eingetragen.",
            classes: "round red",
          });
        } else if (_words(schoolClass).length === 0) {
          Materialize.toast({
            html: "Keine Klassenbezeichnung eingetragen.",
            classes: "round red",
          });
        } else {
          this.seminar = {
            school,
            schoolClass,
            seminarType: selectedGameOption,
            gameMode: selectedModeOption,
          };
          this.addSeminar();
          this.setSeminarType(this.gameSelected.value);
          this.isSeminarStarted = false;
        }
      },
      addSeminar() {
        axios
          .post(`${api.getUrl()}/api/frontend/seminar`, this.seminar)
          .then((response) => {
            this.value = LocalStorageService.getCode();
            Materialize.toast({
              html: "Seminar hinzugefügt",
              classes: "round green",
            });
            this.teamsExist = false;
            this.actualPeriodIndex = 0;
            this.getActiveSeminar();
            this.setSeminarType(response.data.seminarType);
          })
          .catch((e) => {
            this.errors.push(e);
            Materialize.toast({
              html: e.response.data.message,
              classes: "round red",
            });
          });
      },
      startSeminar() {
        axios
          .put(`${api.getUrl()}/api/frontend/seminar`, { initialStart: true })
          .then(() => {
            this.value = LocalStorageService.getCode();
            this.startSeminarDisabled = true;
            this.isSeminarStarted = false;
            Materialize.toast({
              html: "Seminar gestartet",
              classes: "round green",
            });
            this.getActiveSeminar();
          })
          .catch((err) => this.errors.push(err));
      },
      blockWordSearch() {
        axios
          .put(`${api.getUrl()}/api/frontend/seminar`, {
            blockWordSearch: true,
          })
          .then(() => {
            Materialize.toast({
              html: "Suchsel blockiert",
              classes: "round green",
            });
          })
          .catch((err) => this.errors.push(err));
      },
      startNextPeriod() {
        axios
          .put(`${api.getUrl()}/api/frontend/seminar`, {})
          .then(() => {
            this.actualPeriodIndex += 1;
            this.nextPeriodDisabled = true;
            Materialize.toast({
              html: "Spielrunde freigegeben",
              classes: "round green",
            });
            this.getActiveSeminar();
          })
          .catch((err) => this.errors.push(err));
      },
      details(team) {
        this.$router.push({
          name: "ShowTeam",
          params: { id: team.id },
        });
      },
      getNextPeriod() {
        if (this.actualPeriodIndex >= this.lastPeriod) {
          return "";
        }
        if (this.actualPeriodIndex === 0) {
          return this.actualPeriodIndex + 2;
        }
        return this.actualPeriodIndex + 1;
      },
      getSchoolAndClass() {
        return this.actualTeam;
      },
      showModal() {
        this.showTeams = true;
      },
      showTeamActivationModal() {
        this.activateTeams = true;
      },
      showTeamRemoveModal(team) {
        this.removeTeams = true;
      },
      showTeamRenameModal() {
        this.renameTeams = true;
      },
      closeModal() {
        this.showTeams = false;
        this.activateTeams = false;
        this.removeTeams = false;
        this.renameTeams = false;
      },
    },
    methods
  ),
  components: {
    "table-head": TableHead,
    overlay: Overlay,
    loader: Loader,
    "add-team": AddTeam,
    Multiselect,
    QrcodeVue,
  },
};

export default state;
