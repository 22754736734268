<template>
  <div>
    <Reporting_TH v-if="seminar_type === 'trading_heroes'" />
    <Reporting_GH_FH v-if="seminar_type === 'finance_heroes' || seminar_type === 'giro_heroes' || seminar_type === 'young_heroes'" />
    <div
      v-else
      class="message-container"
    >
      {{ message }}
    </div>
  </div>
</template>

<script>
  import axios from "axios";
  import api from "../js/config/api";
  import Reporting_TH from "./Reporting/Trading_Heroes/Reporting_TH.vue";
  import Reporting_GH_FH from "./Reporting/Giro_Finance_Heroes/Reporting_GH_FH.vue";

  export default {
    name: "Main_Reporting",
    components: {Reporting_TH, Reporting_GH_FH},
    data: function () {
      return {
        seminar_type: '',
        message: '',
      }
    },
    methods: {
      getType () {
        axios.get(`${api.getUrl()}/api/frontend/seminar-type/`)
          .then((response) => {
            if (response.data) {
              this.seminar_type = response.data;
            } else {
              this.message = response.data["message"];
            }
          })
          .catch(err => {
            this.errors.push(err);
          })
      }
    },
    mounted() {
      this.getType();
    }
  }
</script>
<style>
  .message-container {
    display: flex;
    justify-content: center;
    font-weight: bold;
    margin-top: 15px;
  }
</style>
