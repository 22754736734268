import Router from './router'
import router from "@/js/router";
import MainMenu from '../templates/MainMenu.vue'
import axios from "axios";
import LocalStorageService from "../js/lib/LocalStorageService";
import api from "./config/api";

const root = {
  name: 'Root',
  data () {
    return {
      navLinks: Router.options.routes,
      seminarType: '',
      errors: [],
      isLoggedIn: false,
    }
  },
  components: {
    'main-menu': MainMenu
  },
  methods: {
    setSeminarType(type) {
      this.seminarType = type;
    },
    getActiveSeminar() {
      axios.get(`${api.getUrl()}/api/frontend/seminar`)
        .then((response) => {
          const seminar = response.data;
          if (seminar.id) {
            this.seminarType = seminar.seminar_type;
          }
        })
        .catch((e) => {
          this.errors.push(e)
        })
    },
    setLoginState(state) {
      this.isLoggedIn = state
      if(!this.isLoggedIn) {
        router.push('/login')
      }
    },
  },
  async mounted() {
    if(LocalStorageService.getAccessToken()) {
      this.isLoggedIn = true
      await this.getActiveSeminar();
    }
  }
};

export default root
