import axios from "axios";

import api from "../config/api";

export default {
  props: {
    isActivateModal: {
      type: Boolean,
      default: false,
    },
    isRemoveModal: {
      type: Boolean,
      default: false,
    },
    isRenameModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected: "",
      teamInfo: [],
      error: "",
      firstName: "",
      lastName: "",
      errors: [],
      newTeamName: "",
    };
  },
  mounted() {
    axios
      .get(`${api.getUrl()}/api/frontend/seminar`)
      .then((response) => {
        response.data.teams.forEach((team) => this.teamInfo.push(team));
      })
      .catch(() => {
        this.error = "Beim laden der Teams ist etwas schief gelaufen.";
      });
  },
  methods: {
    onClickButton() {
      window.location.reload();
      this.$emit("close");
    },
    addMember() {
      if (this.firstName === "" || this.lastName === "") {
        this.error = "Der Name ist erforderlich";
      } else if (this.selected === "") {
        this.error = "Kein Team ausgewählt";
      } else {
        axios
          .post(`${api.getUrl()}/api/frontend/team/${this.selected}/?''`, {
            first_name: this.firstName,
            last_name: this.lastName,
          })
          .then(() => {
            this.onClickButton();
          })
          .catch(() => {
            this.error =
              "Beim Hinzufügen des Mitglieds ist etwas schief gelaufen.";
          });
      }
    },
    setTeamActiveStatus() {
      console.log(this.teamInfo);

      this.teamInfo.forEach((team) => {
        axios
          .post(
            `${api.getUrl()}/api/frontend/team/${
              team.id
            }/?setTeamActiveStatus=${team.is_active}`,
            {}
          )
          .then(() => {
            this.onClickButton();
          })
          .catch(() => {
            this.error =
              "Beim freigeben des Spielstands ist etwas schief gelaufen.";
          });
      });
    },
    removeTeam() {
      axios
        .post(
          `${api.getUrl()}/api/frontend/team/${this.selected}/?removeTeam=True`,
          {}
        )
        .then(() => {
          this.onClickButton();
        })
        .catch(() => {
          this.error = "Beim löschen des Teams ist etwas schief gelaufen.";
        });
    },
    renameTeam() {
      if (this.newTeamName === "") {
        this.error = "Der Name ist erforderlich";
      } else if (this.selected === "") {
        this.error = "Kein Team ausgewählt";
      } else {
        axios
          .post(
            `${api.getUrl()}/api/frontend/team/${
              this.selected
            }/?renameTeam=True`,
            { newTeamName: this.newTeamName }
          )
          .then(() => {
            this.onClickButton();
          })
          .catch(() => {
            this.error = "Beim Team umbenennen ist etwas schief gelaufen.";
          });
      }
    },
  },
};
