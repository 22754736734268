import Vue from 'vue';
import Router from 'vue-router';
import Reporting from '../../templates/Reporting.vue';
import Certificate from '../../templates/Certificate.vue';
import State from '../../templates/State.vue';
import Team from '../../templates/Team.vue';
import DepotGraph from '../../templates/DepotGraph';
import Login from '../../templates/Login';
import Logout from '../../templates/Logout';

Vue.use(Router);

let router = new Router({
  mode: 'history',
  base: '/frontend',
  routes: [
    {
      path: '/login',
      name: 'Login',
      component: Login,
    },
    {
      path: '/',
      redirect: '/state'
    },
    {
      path: '/state',
      name: 'Status',
      component: State,
      meta: {
        requiresAuth: true,
      }
    },
    {
      path: '/reporting',
      name: 'Auswertung',
      component: Reporting,
      meta: {
        requiresAuth: true,
      }
    },
    {
      path: '/reporting/:periodIndex/:id',
      component: Team,
      props: true,
      meta: {
        requiresAuth: true,
      }
    },
    {
      path: '/certificate',
      name: 'Zertifikate',
      component: Certificate,
      meta: {
        requiresAuth: true,
      }
    },
    {
      path: '/depot-graph',
      name: 'Kursverläufe',
      component: DepotGraph,
      meta: {
        requiresAuth: true,
        seminar: ['trading_heroes']
      }
    },
    {
      path: '/logout',
      name: 'Ausloggen',
      component: Logout,
      meta: {
        requiresAuth: true
      }
    }
  ]
});


router.beforeEach((to, from, next) => {
    if(to.matched.some(record => record.meta.requiresAuth)) {
        if (localStorage.getItem('access') === null || localStorage.getItem('access') === '') {
            next({
                path: '/login',
                params: { nextUrl: to.fullPath }
            })
        } else {
            next()
        }
    } else {
        next()
    }
});

export default router
