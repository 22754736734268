const LocalStorageService = (function(){
 var _service;
 function _getService() {
   if(!_service) {
       _service = this;
       return _service
   }
   return _service
 }
 function _setToken(tokenObj) {
   if(tokenObj.access) {
     localStorage.setItem('access', tokenObj.access);
     const jwtData = parseJwt(tokenObj.access);
     this.setSeminarType(jwtData.seminar_type);
     this.setSpecialUser(jwtData.can_start_all_types);
     this.setCode(jwtData.code);
   }

   if(tokenObj.refresh) {
     localStorage.setItem('refresh', tokenObj.refresh);
   }
 }
 function _getAccessToken() {
   return localStorage.getItem('access');
 }
 function _getRefreshToken() {
   return localStorage.getItem('refresh');
 }
 function _clearToken() {
   localStorage.removeItem('access');
   localStorage.removeItem('refresh');
   localStorage.removeItem('seminarType');
   localStorage.removeItem('isSpecialUser');
 }

 function _setSeminarType(seminarType) {
   localStorage.setItem('seminarType', seminarType);
 }

 function _getSeminarType() {
   return localStorage.getItem('seminarType');
 }

 function _setSpecialUser(isSpecialUser) {
   localStorage.setItem('isSpecialUser', isSpecialUser);
 }

 function _getSpecialUser() {
   return localStorage.getItem('isSpecialUser') === 'true';
 }


 function _setCode(code) {
   localStorage.setItem('code', code);
 }

 function _getCode() {
   return localStorage.getItem('code');
 }

return {
   getService : _getService,
   setToken : _setToken,
   getAccessToken : _getAccessToken,
   getRefreshToken : _getRefreshToken,
   clearToken : _clearToken,
   setSeminarType : _setSeminarType,
   getSeminarType : _getSeminarType,
   setSpecialUser : _setSpecialUser,
   getSpecialUser : _getSpecialUser,
   setCode : _setCode,
   getCode : _getCode,
 };
})();
export default LocalStorageService;


function parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}
