// eslint-disable-next-line
import Materialize from 'materialize-css'
import data from '../../lib/sharedComponentData'

const overlay = {
  name: 'Overlay',
  props: ['anker', 'team'],
  data () {
    return data
  },
  mounted () {
    const modal = document.querySelector(`#${this.anker}`);
    Materialize.Modal.init(modal)
  },
  updated () {
    const imagebox = document.querySelector('.materialboxed');
    Materialize.Materialbox.init(imagebox)
  },
  methods: {
    printIban () {
      const { iban } = this.team;
      if (iban !== undefined && iban !== null && iban.length === 22) {
        return {
          character: `${this.team.first_name} ${this.team.last_name}`,
          iban: `${iban.substring(0, 4)} ${iban.substring(4, 8)} ${iban.substring(8, 12)} ${iban.substring(12,
            16)} ${iban.substring(16, 20)} ${iban.substring(20, 22)}`
        }
      }
      return false
    }
  }
};

export default overlay
