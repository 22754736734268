import Materialze from 'materialize-css'
import Router from '../../router'

const teamName = {
  name: 'TeamName',
  props: ['name', 'periodIndex', 'id', 'isTH'],
  data () {
    return {
      showTeamName: false,
      navLinks: Router.options.routes
    }
  },
  updated () {
    const tooltips = document.querySelectorAll('.tooltipped');
    Materialze.Tooltip.init(tooltips, {
      html: 'Teamdetails anzeigen',
      position: 'top'
    })
  },
  methods: {
    changeShowTeamName () {
      this.showTeamName = !this.showTeamName
    },
    blendIn () {
      this.showTeamName = true
    },
    blendOut () {
      this.showTeamName = false
    }
  }
};

export default teamName
